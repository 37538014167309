import { get, post } from './request'
import config from './config'

export const userInfoController = p => get(config.userInfoController, p);
export const userInfoControllerPost = p => post(config.userInfoController, p);
export const identityCheck = p => post(config.identityCheck, p);
export const modifyUserInfo = p => post(config.modifyUserInfo, p)
export const uploadFile = p => post(config.uploadFile, p)
export const modifyPassWord = p => get(config.modifyPassWord, p)
export const downloadInfo = p => get(config.downloadInfo, p)
export const exhibition = p => get(config.exhibition, p)
export const exhibitionApplication = p => get(config.exhibitionApplication, p)
export const exhibitionConfirMenter = p => get(config.exhibitionConfirMenter, p)
export const saveExhibitionApplication = p => post(config.saveExhibitionApplication, p)
export const upDetermineInfo = p => get(config.upDetermineInfo, p)
export const levelDistributionDetails = p => get(config.levelDistributionDetails, p)
export const lecelIntroduceDetails = p => get(config.lecelIntroduceDetails, p)
export const questionInfoConfig = p => get(config.questionInfoConfig, p)
export const questionInfoConfigPost = p => post(config.questionInfoConfig, p)
export const examInfoConfig = p => get(config.examInfoConfig, p)
export const examInfoConfigPost = p => post(config.examInfoConfig, p)
export const queryUserRecord = p => get(config.queryUserRecord, p)
export const queryExamInfo = p => get(config.queryExamInfo, p)
export const queryTimeInfo = p => get(config.queryTimeInfo, p)
export const aliPay = p => get(config.aliPay, p)
export const wxPay = p => get(config.wxPay, p)
export const selectOrderInfo = p => get(config.selectOrderInfo, p)
export const deleteOrderInfo = p => get(config.deleteOrderInfo, p)
export const addRefundOrderInfo = p => post(config.addRefundOrderInfo, p)
export const userReplacetel = p => get(config.userReplacetel, p)
export const queryAchievement = p => get(config.queryAchievement, p)
export const queryAdmissionNumber = p => get(config.queryAdmissionNumber, p)
export const recordEnterExamTime = p => get(config.recordEnterExamTime, p)