<template>
 <div :class="['main ub ub-ver reload']">
     <!-- 头部 -->
     <div class="main-header ub " v-if="queData">
         <div class="ub" v-if='getQuesitionData.pageType == 1'>
             <div>{{queData.subName}}</div>
         </div>
         <el-breadcrumb separator-class="el-icon-arrow-right" class="ub-f1" v-else>
            <el-breadcrumb-item :to="{ path: '/evaluation' }">在线测评</el-breadcrumb-item>
            <el-breadcrumb-item>{{getBreadcrumb}}</el-breadcrumb-item>
         </el-breadcrumb> 
         <div class="ub-f1 paper-name">{{getQuesitionData.examName}}</div>
         <div class="paper-time student" v-if='getQuesitionData.pageType == 4' @click="showStudents()"><span>作答学生</span></div>
         <div class="paper-time student" v-else-if='getQuesitionData.pageType == 3'></div>
         <div class="paper-time" v-else>考试用时：<span>{{formattedTime}}</span></div>
         <div class="ub ub-ac" @click="goBack" v-if="newPaperParams.isMockExamType"><el-button type="danger">退出考试</el-button></div>
     </div>
     <div class="main-header ub " v-else>
         <el-breadcrumb separator-class="el-icon-arrow-right" class="ub-f1">
            <el-breadcrumb-item :to="{ path: '/evaluation' }">在线测评</el-breadcrumb-item>
            <el-breadcrumb-item>{{getBreadcrumb}}</el-breadcrumb-item>
         </el-breadcrumb> 
     </div>
     <!-- 头部 end -->
     <div class="main-body ub ub-f1">
        <QueInfoArea class="main-left" :queData="queData" v-if="queData"></QueInfoArea>
        <div class="ub-f1" v-if="queData">
            <el-scrollbar style="height:100%" ref="scrollbar" class="content-scrollBar">
                <div class="ub ub-f1" style="height: 100%">
                    <QueStemArea class="main-center"></QueStemArea>
                    <QueOptionArea class="main-right" :pageName="paperParams.pageName" :competitionId="paperParams.competitionId"></QueOptionArea>
                </div>
            </el-scrollbar>
        </div>
        <el-empty :description="isGetDataErrTip" v-if="isGetDataErr"></el-empty>
        <!-- <Loading v-if="isGetDataLoading" :isLoading="isLeverLoading" loadText="信息加载中..."></Loading> -->
     </div>

     <el-dialog
        title="提示"
        custom-class="default-dialog big-img-dialog"
        :model-value="bigImgDialogVisible"
        :before-close="handleClose">
        <img :src="bigImgSrc" alt="">
    </el-dialog>
 </div>
</template>

<script>
import { questionInfoConfig, recordEnterExamTime, examInfoConfigPost } from '@/http/api'
import { mapGetters } from 'vuex'
 import QueInfoArea from './QueInfoArea.vue'
 import QueStemArea from './QueStemArea.vue'
 import QueOptionArea from './QueOptionArea.vue'
 import queJs from '@/utils/que.js'
 import $ from 'jquery'
 export default {
    props: ['paperParams'],
   data () {
     return {
         newPaperParams: '',
         bigImgDialogVisible: false,
         bigImgSrc: '',
         queData: null,
         isGetDataLoading: false,
         isGetDataErr: false,
         isGetDataErrTip: '',
        //  倒计时
         timer: null,
         countdown: 0
     }
   },
   components: {
       QueInfoArea,
       QueStemArea,
       QueOptionArea
   },

   computed: {
    ...mapGetters([
      'getUserInfo',
      'getQuesitionData'
    ]),

    getBreadcrumb () {
        return queJs.getBreadcrumbName(this.getQuesitionData.pageType)
    },
    formattedTime() {
        const hours = Math.floor(this.countdown / 60 / 60);
        const minutes = Math.floor((this.countdown % 3600) / 60);
        const seconds = this.countdown % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    takeTime() {
        console.log(this.getQuesitionData.quesitionInfoData.time * 60)
        console.log(this.getQuesitionData.currentTime)
        return this.getQuesitionData.quesitionInfoData.time * 60 - this.getQuesitionData.currentTime
    }
   },

    created () {
        /* this.paperParams = {
            examId: "20426",
            projectUrl: "https://pbl.zdw000.com/question/ycsj_questions_bank/api/",
            type: 1,
            classId: 1800314,
            uid: 2000002345,
            role: '1'
        } */
        /* this.paperParams = {
            examId: "10102",
            projectUrl: "https://pbl.zdw000.com/question/ycsj_questions_bank/api/",
            type: 2,
            classId: 1800311,
            uid: 366,
            role: '1'
        } */
        /* this.paperParams = {
            examId: "10102",
            projectUrl: "https://pbl.zdw000.com/question/ycsj_questions_bank/api/",
            type: 4,
            classId: 1800311,
            uid: 2000002345,
            role: '1'
        } */
        if (this.paperParams.examId) {
            this.newPaperParams = this.paperParams
            sessionStorage.setItem('newPaperParams', JSON.stringify(this.paperParams))
        } else {
            this.newPaperParams = JSON.parse(sessionStorage.getItem('newPaperParams'))
        }
        this.$store.dispatch('setPaperParams', this.newPaperParams)
        this.getData()
    },

    mounted () {
        this.listenPage()

        window.addEventListener('click', e => {
            if (e.target.localName == 'img' && e.target.currentSrc.indexOf('https://pc.zdw000.com/') != -1) {
                this.bigImgSrc = e.target.currentSrc
                this.bigImgDialogVisible = true
            }
         },  false);
    },

    unmounted () {
        clearInterval(this.timer);
        window.onbeforeunload = null
    },

    methods: {
        goBack() {
            this.$router.replace({ path: `/evaluation/${this.newPaperParams.routeName.toLowerCase()}`})
        },
        getData () {
            let params = {}
            params.type = this.getQuesitionData.pageType
            params.examId =  this.getQuesitionData.examId
            localStorage.removeItem('isReload')
            if (this.getQuesitionData.pageType == 1) { // 做题
                localStorage.setItem('isReload', true)
            } else if (this.getQuesitionData.pageType == 2) { // 解析
                params.type = 3,
                params.userId = this.getQuesitionData.uid
            } else if (this.getQuesitionData.pageType == 3) {
                params.type = 5
                params.userId = this.getQuesitionData.uid
            } else if (this.getQuesitionData.pageType == 4) {
                // ...
            }
            questionInfoConfig(params).then(res => {
                console.log(res)

                let data = res.data
                this.queData = data
                // queState.setQueInfoData(data)
                this.$store.dispatch('setQueInfoData', data)
                let quesitionData = []

                for (let i in data) {
                    if (i.indexOf('QuestionData') != -1) {
                        quesitionData.push(data[i])
                    }
                }
                quesitionData.sort(this.soryBy('questionType'))
                this.$store.dispatch('setActQueData', {})
                /* console.log(this.getQuesitionData.answerSheetIndex)
                console.log(quesitionData)
                for (let i = 0; i < quesitionData.length; i++) {
                    console.log(quesitionData.length)
                    for (let j = 0; j < quesitionData[i].list.length; j++) {
                        if (!this.getQuesitionData.actQueData.questionType) {
                            this.$store.dispatch('setActQueData', quesitionData[i].list[j])
                            console.log(quesitionData[i].list[j])
                        }
                    }
                } */

                // 初始化一个二维数组，用于存放分组后的数据
                const groupedData = [[], []];

                // 遍历原始数据，并根据 questionType 分组
                quesitionData.forEach(item => {
                if (item.questionType >= 1 && item.questionType <= 3) {
                    groupedData[0].push(item);
                } else if (item.questionType === 4) {
                    groupedData[1].push(item);
                }
                });

                // 过滤掉空数组
                const filteredGroupedData = groupedData.filter(group => group.length > 0);
                this.$store.dispatch('setQueData', filteredGroupedData)
                this.$store.dispatch('setActQueData', filteredGroupedData[this.getQuesitionData.answerSheetIndex][0].list[0])

                if (this.getQuesitionData.pageType == 1) {
                    // 获取考试倒计时
                    this.getRecordEnterExamTime()
                } else if (this.getQuesitionData.pageType == 2) {
                    this.countdown = data.answerTime
                } else if (this.getQuesitionData.pageType == 3) {
                    this.$store.dispatch('setUserCorrectOrNot')
                }
                
            }).catch((err) => {
                this.isGetDataErr = true
                this.isGetDataErrTip = err && err.data.msg ? err.data.msg : '加载失败！'
            })
        },

        // 获取考试倒计时时间
        getRecordEnterExamTime() {
            recordEnterExamTime({
                userId: this.getQuesitionData.uid,
                competitionId: this.getQuesitionData.competitionId
            }).then(res => {
                console.log(res)
                if (res.data < 0) {
                    this.$message({
                        message: "考试已结束",
                        type: "warning",
                        showClose: true
                    });
                    setTimeout(() => {
                        this.$router.replace({name: this.getQuesitionData.routeName})
                    }, 1000)
                } else {
                    this.countdown = res.data
                    this.timer = setInterval(this.updateCountdown, 1000);
                }
            })
        },

        soryBy () {
            return function (a, b) {
                return a.questionType - b.questionType
            }
        },
        // 更新倒计时
        updateCountdown() {
            if (this.countdown > 0) {
                this.countdown--;
                this.$store.dispatch('setCurrentTime', this.countdown)
            } else {
                // location.reload();
                this.submitHandpaper()
                clearInterval(this.timer);
            }
        },

        /**
         * 显示已答该题学生 
         */
        showStudents () {
            let questionStudent = this.getQuesitionData.actQueData.questionStudent
            this.$alert(questionStudent.length > 0 ? questionStudent.join(',') : '暂无答题学生！', '提示', {
                confirmButtonText: '确定',
                customClass: 'messageBox-default explain',
                callback: () => {
                }
            });
        },

        listenPage() {
            if (this.getQuesitionData.pageType == 1) {
                window.onbeforeunload = function (e) {
                    e = e || window.event;
                    if (e) {
                        e.returnValue = '关闭提示';
                    }
                    return '关闭提示';
                };
            } else {
                window.onbeforeunload = null
            }
        },

        handleClose() {
            this.bigImgDialogVisible = false
        },

        submitHandpaper() {
            this.$messageBox.confirm('考试时间到，请提交试卷！', '提示', {
                    confirmButtonText: '确认提交',
                    type: 'warning',
                    showClose: false,
                    showCancelButton: false,
                    center: true,
                    closeOnClickModal: false,
                    customClass: 'default-dialog submit-message'
                    // custom-class: 'default-dialog'
                }).then(() => {
                    console.log('aaaa')
                    this.requestPaper()
                }).catch(() => {
                    // 继续答题
                })
        },

        requestPaper() {
            let userAnswer = {}
            // 遍历外层数组
            for (const outerItem of this.getQuesitionData.quesitionData) {
                // 遍历内层数组
                for (const innerItem of outerItem) {
                    // 遍历 list 数组
                    for (const listItem of innerItem.list) {
                        // 将 id 和 num 放入 resultObject
                        userAnswer[listItem.questionId] = listItem.userAnswer ? listItem.userAnswer : '';
                    }
                }
            }
            console.log()
            let formData = new FormData();
            //参数
            formData.append("type", 2);
            formData.append("answer", JSON.stringify(userAnswer));
            formData.append("userId", this.getQuesitionData.uid);
            formData.append("examId", this.getQuesitionData.examId);
            formData.append("answerTime", this.takeTime);
            formData.append("role", this.getQuesitionData.role);
            examInfoConfigPost(formData).then(res => {
                this.$message({
                    message: "试卷提交成功！",
                    type: "success",
                    showClose: true
                });
                setTimeout(() => {
                    this.$router.replace({name: this.getQuesitionData.routeName})
                }, 1000)
            }).catch(() => {
                this.submitHandpaper()
            })
        }
    },

   watch: {
       'getQuesitionData.actQueData' (newVal, oldVal) {
            if (newVal == oldVal) {
                return
            }
            $('.que-option-body').css('opacity', '0')
            $('.que-stem-body').css('opacity', '0')
            setTimeout(() => {
                // 选项图片缩小
                if ($('.que-option-body img').length > 0 &&  newVal.questionType != 4) {
                    $('.option-item img').each(function() {
                        let imgWidth = $(this).width()
                        $(this).css({'width': `${imgWidth * newVal.subjectValue}`})
                        $(this).parents('.que-option-body').css('opacity', '1')
                    })
                } else {
                    $('.que-option-body').css('opacity', '1')
                }

                // 题干图片缩小
                if ($('.que-stem-body img').length > 0) {
                    $('.que-satem img').each(function() {
                        let imgWidth = $(this).width()
                        $(this).css('width', `${imgWidth * newVal.titleValue}`)
                        $(this).parents('.que-stem-body').css('opacity', '1')
                    })
                } else {
                    $('.que-stem-body').css('opacity', '1')
                }
            }, 300)
       }
   }
 }
</script>
<style lang="scss">
.main {
    padding: 0 4px;
    height: 100%;
    box-sizing: border-box;
    background-color: #eff3f8;
    text-align: left;
    font-size: 18px;

    .main-header {
        height: 68px;
        line-height: 68px;
        font-size: 24px;
        box-sizing: border-box;
        .paper-name {
            margin-left: 42px;
            margin-right: 10px;
        }
        .paper-time {
            font-size: 16px;
            padding-right: 15px;
            span {
                font-weight: bold;
            }
        }

        .paper-time.student {
            color: var(--el-color-primary);
            cursor: pointer;
        }

        .el-breadcrumb {
            line-height: 68px;
            font-size: 16px;

            .el-breadcrumb__item {
                .el-breadcrumb__separator,
                .el-breadcrumb__inner {
                    color: #000;
                    font-weight: 700;
                }
            }

            .el-breadcrumb__item:last-child {
                .el-breadcrumb__inner {
                    color: var(--el-color-primary);
                }
            }
        }
    }

    .el-scrollbar__view {
        height: 100%;
    }

    .main-left, .main-center, .main-right {
        background-color: #fff;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .main-left {
        width: 266px;
        min-width: 266px;
    }

    .content-scrollBar {
        .el-scrollbar__wrap {
            overflow-x: hidden;

            .main-center {
                width: 50%;
                min-width: 458px;
                margin: 0 4px;
            }

            .main-right {
                width: 50%;
                min-width: 458px;
            }
        }
    }

    .el-empty {
        width: 100%;
    }

    .big-img-dialog {
        background-color: unset;
        box-shadow: unset;
        width: 100%;
        text-align: center;

        .el-dialog__header {
            background: unset;
            .el-dialog__title {
                display: none;
            }

            .el-dialog__headerbtn {
                i {
                    color: #fff;
                }

                i::before {
                    color: #fff;
                    font-size: 50px;
                }
            }
        }

        .el-dialog__body {
            img {
                max-height: 100%;
                max-width: 100%;
            }
        }

        .el-dialog__footer {
            display: none;
        }
    }
}
 
</style>
