import axios from 'axios'    //引入axios
// import QS from 'qs'    //引入qs，用来序列化post类型的数据，否则后端无法接收到数据
import { ElMessage } from 'element-plus'
import { AuthService } from '@/utils/AuthService';
import router from '@/router';
import store from '@/store';

const errMsg = (state) => {
    switch (Number(state)) {
        case 208:
            setTimeout(() => {
                AuthService.logout()
                router.go(0);
            }, 1500)
            break;
        default:
            break;
    }
}

// 设置post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
axios.defaults.withCredentials = false;//在跨域请求时，不会携带用户凭证；返回的 response 里也会忽略 cookie

//创建axios实例，请求超时时间为300秒，因为项目中有多个域名，所以对应的也要创建多个axios实例
const instance = axios.create({
  timeout: 25000,
});

// 拦截器
instance.interceptors.request.use(
    config => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
        if (store.getters.userAccount) {
            const token = store.getters.userAccount.token;
            token && (config.headers.token = token);
        }
        return config;
    },
    error => Promise.error(error)
)
// 响应拦截器

instance.interceptors.response.use(
    // 请求成功
    res => {
        if (res) {
            if (res.status === 200) {
                if (res.data.status === '200' || res.data.state === '200') {
                    return Promise.resolve(res)
                } else {
                    ElMessage.error(res.data.msg)
                    errMsg(res.data.state);
                    res.errText = errMsg(res.data.state, res.data.msg)
                    return Promise.reject(res)
                }
            } else {
                ElMessage.error('加载失败！');
                return Promise.reject(res)
            }
        }
        
    },
    // 请求失败
    error => {
        const { response } = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围
            // errorHandle(response.status, response.data.message);
            return Promise.reject(response);
        } else {
            // 处理断网的情况
            // eg:请求超时或断网时，更新state的network状态
            // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
            // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
            //store.commit('changeNetwork', false);
            ElMessage.error('网络连接错误！');
            return Promise.reject({
                data: {
                    msg: '网络连接错误！'
                }
            });
        }
    }
);

// export default instance

/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
 export function get(url, params){
    return new Promise((resolve, reject) =>{        
        instance.get(url, {            
            params: params        
        })        
        .then(res => {            
            resolve(res.data);        
        })        
        .catch(err => {       
            reject(err)        
        })    
    });
}

/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
 export function post(url, params) { 
    return new Promise((resolve, reject) => {         
        instance.post(url, params)        
        .then(res => {            
            resolve(res.data);        
        })        
        .catch(err => {            
            reject(err.data)        
        })    
    });
}
 

// 1.去掉了之前get和post方法的封装，通过创建一个axios实例然后export default方法导出，这样使用起来更灵活一些。
// 2.去掉了通过环境变量控制baseUrl的值。考虑到接口会有多个不同域名的情况，所以准备通过js变量来控制接口域名。这点具体在api里会介绍。
// 3.增加了请求超时，即断网状态的处理。说下思路，当断网时，通过更新vuex中network的状态来控制断网提示组件的显示隐藏。断网提示一般会有重新加载数据的操作，这步会在后面对应的地方介绍。
// 4.公用函数进行抽出，简化代码，尽量保证单一职责原则。