<template>
  <div class="the-header">
        <div class="header-top">
            <div class="container ub ub-ac ub-be">
                <router-link to="/"><el-image class="logo" :src="logo" :fit="fit" /></router-link>
                <div class="rignt-info ub ub-ac">
                    <el-button type="danger" round @click="queryExamBtn" v-if="shwoQueryBtn">进入考试</el-button>
                    <el-button type="danger" round @click="queryBtn" v-if="shwoQueryBtn">查询</el-button>
                    <el-button class="record-button" color="#ffb71d" v-if="showRecordBtn" round @click="toApplicationRecord">报考记录</el-button>
                    <el-tooltip
                        v-if="isDownloadTicket" 
                        popper-class="download-ticket"
                        effect="dark"
                        content="下载准考证"
                        placement="top"
                        :visible="true"
                        offset="0"
                    >
                        <div class="iconfont icon-yunxiazai" @click="downloadTicket()"></div>
                    </el-tooltip>
                    <div v-if="!userAccount">
                        <el-button type="primary" round @click="loginBtn">登录</el-button>
                        <el-button type="primary" round @click="registerBtn">注册</el-button>
                    </div>
                    <div v-else class="user-name">
                        <el-dropdown @command="handleCommand">
                            <span class="el-dropdown-link">
                            {{ userAccount.userName }}
                            </span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item class="tx-c fw-b" command="personal">（{{ roleName }}）</el-dropdown-item>
                                    <el-dropdown-item command="personal">个人中心</el-dropdown-item>
                                    <el-dropdown-item command="password">修改密码</el-dropdown-item>
                                    <el-dropdown-item command="account">修改账号</el-dropdown-item>
                                    <el-dropdown-item command="signOut" divided>退出登录</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <!-- 菜单 -->
        <div class="header-menu">
            <el-menu
                :default-active="activeIndex"
                class="container"
                mode="horizontal"
                @select="handleSelect"
            >   
                <template v-for="(item, index) in menuList" :key="index">
                    <template v-if="item.children && item.children.length > 0">
                        <el-sub-menu :index="index + 1" popper-class="second-menu-popup">
                            <template #title><router-link :to="item.path">{{ item.meta.title }}</router-link></template>
                            <el-menu-item :index="(index + 1) + '-' + (subIndex + 1)" v-for="(subItem, subIndex) in item.children" :key="subIndex">
                                <router-link :to="subItem.path">{{ subItem.meta.title }}</router-link>
                            </el-menu-item>
                        </el-sub-menu>
                    </template>
                    <template v-else>
                        <el-menu-item :index="index + 1">
                            <router-link :to="item.path">{{ item.meta.title }}</router-link>
                        </el-menu-item>
                    </template>
                </template>
            </el-menu>
        </div>
        
  </div>
</template>

<script>
    import { ref, computed, onMounted } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import { AuthService } from '@/utils/AuthService';
    import data from '@/utils/data'

    export default {
        setup() {
            const logo = require("../assets/logo.png")
            const store = useStore();
            const router = useRouter();
            const route = useRoute();
            const menuList = ref([])

            const loginBtn = () => {
                router.push('/login');
            }
            const registerBtn = () => {
                router.push({ name: 'Login', params: { type: 3 } })
            }
            const userAccount = computed(() => store.getters.userAccount)
            const roleName = userAccount.value ? data.roleList.find(item => item.id == userAccount.value.role).name : ''

            // onMounted
            onMounted(() => {
                getMenu()
            })

            const isStudent = computed(() => {
                return userAccount.value && userAccount.value.role == 0 
            })

            const shwoQueryBtn = computed(() => {
                return isStudent.value || !userAccount.value
            })

            const isDownloadTicket = computed(() => {
                return shwoQueryBtn.value
            })

            function getMenu() {
                const router = useRouter();
                // const route = useRoute();
                const allRoutes = router.getRoutes();
                menuList.value = allRoutes
                menuList.value = allRoutes
                    .filter((item) => item.meta.isHeaderMenu)
                    .map((item) => {
                        const filteredSecond = item.children.filter((secondItem) => secondItem.meta.isSecondMenu);
                        return { ...item, children: filteredSecond}
                    })
            }

            function handleCommand(value) {
                if (value == 'personal') {
                    router.push('/personal')
                } else if (value == 'password') {
                    router.push('/modify-password')
                } else if (value == 'account') {
                    router.push('/modify-account')
                } else if (value == 'signOut') {
                    AuthService.logout()
                    store.commit('setUserAccount', '');
                    router.push('/');
                }
            }

            /**
             * 报考记录
             */
            function toApplicationRecord() {
                router.push('/personal/application-record');
            }

            /**
             * 下载准考证
             */
            function downloadTicket() {
                router.push('/personal/application-record');
            }

            /**
             * 查询
             */
            function queryBtn() {
                router.push('/query-index');
            }

            /**
             * 查询考试信息
             */
             function queryExamBtn() {
                let ticketNumber = sessionStorage.getItem('ticketNumber')
                if (ticketNumber) {
                    sessionStorage.removeItem('ticketNumber')
                    if (route.path == '/query-exam') {
                        setTimeout(() => {
                            window.location.reload()
                        }, 200)
                    } else {
                        router.push('/query-exam');
                    }
                } else {
                    router.push('/query-exam');
                }
            }

            return {
                logo,
                roleName,
                menuList,
                loginBtn,
                registerBtn,
                userAccount,
                handleCommand,
                downloadTicket,
                toApplicationRecord,
                queryBtn,
                isStudent,
                shwoQueryBtn,
                isDownloadTicket,
                queryExamBtn
            }
        }
    }
</script>

<style lang="scss">
.the-header {
    .header-top {
        .container {
            height: 127px;
            .logo {
                width: 451px;
                height: 79px;
            }
            .rignt-info {
                .el-button {
                    margin-right: 18px;
                    margin-left: 0;
                }
                .record-button {
                    color: #ffffff;
                }
                .icon-yunxiazai {
                    font-size: 46px;
                    color: #bfbfbf;
                    margin: 0 18px 0 0;
                    cursor: pointer;
                }

                .user-name {
                    .el-dropdown {
                        .el-tooltip__trigger {
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 24px;
                            display: block;
                            color: #000000;
                        }
                        .el-tooltip__trigger:focus-visible {
                            outline: unset;
                        }
                    }
                }
            }
            .el-button {
                width: 92px;
            }  
        }
    }
    /* 菜单 */
    .header-menu {
        background-color: #fff;
        border-top: 1px solid #eee8e3;
        border-bottom: 1px solid #eee8e3;
        .el-menu{
            height: 65px;
            border: 0px;
            .el-menu-item {
                font-size: 18px;
                padding: 0;
                a {
                    padding: 0 20px;
                }
            }

            .el-sub-menu {
                .el-sub-menu__title {
                    font-size: 18px;
                    a {
                        color: #000000 !important;
                    }
                }
            }
        }
    }
}

.download-ticket.el-popper.is-dark {
    background-color: #ff642e;
    border-color: #ff642e;
    padding: 0 5px;
    .el-popper__arrow::before {
        background-color: #ff642e;
        border-color: #ff642e;
    }
}

.second-menu-popup {
    .el-menu {
        .el-menu-item {
            padding: 0 !important;
            a {
                display: block;
                padding: 0 10px;
                width: 100%;
            }
        }
    }
}
</style>