<template>
  <div class="evaluation-content-model" v-loading="loading">
    <template v-if="type == 1">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick" v-if="tabList.length > 0">
        <el-tab-pane :label="examinationData(tabItem.exhibitionTime)" :name="index" v-for="(tabItem, index) in tabList" :key="index">
          <evaluation-cell v-for="(item) in evaluationData" :key="item.competitionId" :item="item" :id="id"></evaluation-cell>
          <div v-if="!loading && evaluationData.length == 0">暂无数据</div>
        </el-tab-pane>
      </el-tabs>
      <div v-if="!loading && tabList.length == 0">暂无数据</div>
    </template>
    <template v-else>
      <user-evaluation-cell v-for="(item) in evaluationData" :key="item.competitionId" :item="item" :id="id"></user-evaluation-cell>
      <div v-if="!loading && evaluationData.length == 0">暂无数据</div>
    </template>
  </div>
</template>

<script>
import { exhibition, queryUserRecord, queryTimeInfo } from '@/http/api'
import { ref, computed, onMounted, reactive } from 'vue'
import EvaluationCell from './EvaluationCell.vue'
import UserEvaluationCell from './UserEvaluationCell.vue'
import store from '@/store'
export default {
  components: { EvaluationCell, UserEvaluationCell },
  props: {
    type: { // 1 在线测评模块数据 2 个人中心-报考记录数据
      type: Number,
      default: 1
    },
    id: {
        type: Number,
        default: 1
    }
  },
  setup(props) {
    const loading = ref(true)
    const evaluationData = reactive([])
    const tabList = reactive([])
    const userAccount = computed(() => store.getters.userAccount)
    const activeName = ref(0)
    onMounted(() => {
        if (props.type == 1) {
          getTimeInfo()
        } else {
          getData()
        }
    })
    function getData() {
        loading.value = true
        queryUserRecord({
          userId: userAccount.value.userId
        }).then((res) => {
          evaluationData.length = 0
          evaluationData.push(...res.data);
          loading.value = false
        }).catch((err) => {
            loading.value = false
        })
    }

    function reviewerData(exhibitionTimeId) {
      loading.value = true
      exhibition({
        userId: userAccount.value.userId,
        examinationType: props.id,
        exhibitionTimeId: exhibitionTimeId ? exhibitionTimeId : tabList[activeName.value].exhibitionTimeId,
        userIdCard: userAccount.value.userIdCard
      }).then((res) => {
            evaluationData.length = 0
            evaluationData.push(...res.data);
            loading.value = false
        }).catch((err) => {
            loading.value = false
        })
    }

    function getTimeInfo() {
      queryTimeInfo({
        examinationType: props.id
      }).then((res) => {
            tabList.length = 0
            tabList.push(...res.data);
            reviewerData()
      }).catch((err) => {
            loading.value = false
      })
    }

    function handleClick() {
      reviewerData()
    }

    function examinationData(date) {
      if (date) {
        let dateArr = date.split('-')
        return `${dateArr[0]}年${dateArr[1]}月考`
      }
      return ''
      
    }

    function isObjectEmpty(obj) {
      // 使用 Object.keys() 获取对象的键，然后检查键的数量是否为 0
      return Object.keys(obj).length === 0;
    }
    return {
        loading,
        userAccount,
        evaluationData,
        tabList,
        examinationData,
        handleClick,
        activeName,
        isObjectEmpty
    }
  }
}
</script>

<style lang="scss">
.evaluation-content-model {
  min-height: 300px;
  .evaluation-item-model {
    .examination-date {
      font-size: 22px;
      font-weight: bold;
      line-height: 60px;
    }
  }
}
</style>